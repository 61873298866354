<template>
  <el-main>
    <el-table
        :data="tableData"
        style="width: 50%">
      <el-table-column
          prop="platform_name"
          label="平台"
      >
      </el-table-column>
      <el-table-column
          prop="type_cn"
          label="任务类型"
      >
      </el-table-column>
      <el-table-column
          label="操作">
        <template slot-scope="scope">
          <el-switch
              v-model="status[scope.row.id]"
              @change="changeStatus(scope)"
          >
          </el-switch>
        </template>
      </el-table-column>
    </el-table>
  </el-main>
</template>

<script>
export default {
  name: "SystemConfig",
  created() {
    let me = this;
    me.axios.get('/api/platform/list').then(res => {
      res.forEach(platform => {
        me.map.platform['id_' + platform.id] = platform.name;
      })
      me.getTable();
    })
  },
  data() {
    return {
      map: {
        platform: {},
        type: {
          'id_1': '关键词',
          'id_2': '评论'
        }
      },
      rawData: [],
      status: {
        '1': false,
        '2': false,
        '3': false,
        '4': false,
        '5': false,
        '6': false,
        '7': false,
        '8': false,
        '9': false,
        '10': false,
        '11': false,
        '12': false
      }
    }
  },
  computed: {
    tableData() {
      let me = this;
      if (!me.rawData) {
        return [];
      }
      return me.rawData.map(val => {
        val['platform_name'] = me.map.platform['id_' + val['platform_id']];
        val['type_cn'] = me.map.type['id_' + val['type']];
        return val;
      });
    }
  },
  methods: {
    getTable() {
      let me = this;
      me.axios.get('/api/task/getAllTasks').then(res => {
        me.rawData = res;
        if (me.rawData) {
          for (let i = 0; i < me.rawData.length; i++) {
            me.status[me.rawData[i]['id']] = me.rawData[i]['is_frozen'] == 0;
          }
        }
      })
    },
    changeStatus(scope) {
      let me = this;
      console.log(scope);
      let id = scope.row.id;
      let value = me.status[id];
      if (value) {
        me.enableTask(scope);
      } else {
        me.disableTask(scope);
      }
    },
    disableTask(scope) {
      let me = this;
      me.axios.post('/api/task/disableTask', {platform_id: scope.row.platform_id, type: scope.row.type}).then(() => {
        me.$message.success("停用成功");
        me.getTable();
      })
    },
    enableTask(scope) {
      let me = this;
      me.axios.post('/api/task/enableTask', {platform_id: scope.row.platform_id, type: scope.row.type}).then(() => {
        me.$message.success("启用成功");
        me.getTable();
      })
    }
  }
}
</script>

<style scoped>

</style>